$color-primary: #000000;

.Contact {
  font-family: 'inria serif', serif;
  font-style: italic;
  & h1 {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}
.form-quotation {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 500px;
  margin: auto;
  padding: 20px;
  //   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // use it only on computer

  label {

    color: #333;
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  textarea,
  select {
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;

    &:focus {
      border-color: $color-primary;
      outline: none;
    }
  }

  textarea {
    height: 100px;
    resize: vertical;
  }

  button[type='submit'] {
    background-color: $color-primary;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: darken($color-primary, 10%);
    }
  }
}
.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.ReactModalPortal{
    & button{
          display: inline-block;
  background-color: #007bff; /* Bleu Bootstrap */
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
    }
}
/* Styles pour la modale */
.react-modal {
  background: white;
  max-width: 500px;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  outline: none;
}

/* Styles pour le titre de la modale */
.modal-title {
  font-size: 24px;
  margin-bottom: 15px;
}

/* Styles pour le contenu de la modale */
.modal-content {
  margin-bottom: 20px;
}

/* Styles pour le bouton */
.modal-button {
  display: inline-block;
  background-color: #007bff; /* Bleu Bootstrap */
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.modal-button:hover {
  background-color: #0056b3; /* Bleu foncé pour le survol */
}
