@import url('https://fonts.googleapis.com/css2?family=Inria+Serif&display=swap');
@keyframes pulse {
  0%, 100% { transform: scale(1); }
  50% { transform: scaleX(1.05); }
}

.Home{
    &__contact{
        width: 100%;
        margin: auto;
        text-align: center;
        padding: 20px;
        font-family: "inria serif", serif;
        font-size: 20px;
        font-weight: bold;
        font-style: italic;
    //     width: 100%;
    // display: flex;
    // justify-content: center;
    /* border: solid 1px black; */
    background: #ff4444;
    animation: pulse 2s infinite;
    & a{
            color: white;
            text-decoration: none;

            padding-bottom: 2px;
            border-bottom: dotted 2px white;

        }
    }
    &__content{
        &--text{
            padding-top: 20px;
            padding-bottom: 20px;
            h1{
                width: 100%;
                margin: auto;
                text-align: center;
                padding: 20px;
                font-family: "inria serif", serif;
                font-size: 30px;
                font-weight: bold;
                font-style: italic;
            }
            p{
                width: 100%;
                margin: auto;
                text-align: center;
                padding: 20px;
                font-family: "inria serif", serif;
                font-style: italic;
                padding: 10px;
            }
        }
        &--image{
            img{

                width: 100%;
                object-fit: cover;
                max-height: 200px;
            }
        }
        &--map{
            width: 100%;
            height: 500px;
            & iframe{
                width: 100%;
                height: 100%;
                border: none;
            }
        }
    }
}


.separator{
    width: 100%;
    margin: auto;
    text-align: center;
    img{
        width: 100px;
        height: 100px;
    }
}