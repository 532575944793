.header {
  width: 100%;
  &__banner {
    background-color: black;
    &--image {
      width: 100%;
      object-fit: contain;
      max-height: 200px;
    }
    &--burger-menu {
      float: right;
      position: relative;
      bottom: 40px;
      width: 30px;
      height: 30px;
      border: none;
      background: transparent;
      color: white;
      font-size: 25px;
      right: 5px;

      &:hover {
        cursor: pointer;
        color: #f1c40f;
      }
    }
  }
}
.burger-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: flex-end; // Aligner le menu à droite
  & a {
    color: black;
    text-decoration: none;
    &:hover {
      color: #f1c40f;
      cursor: pointer;
    }
  }
  .burger-menu-nav {
    width: 60%; // Ajuster la largeur à 60%
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
    text-align: right; // Aligner le texte à droite

    ul {
      list-style: none;
      padding: 0;

      li {
        padding: 10px 0;
        border-bottom: 1px solid #ccc;
        text-align: center;

      }
    }

    &__contact {
      max-width: 55%;
      position: absolute;
      bottom: 0;
      right: 10px;
      border: solid 1px #e7e7e7;
      padding: 10px;
      width: 100%;
      margin-bottom: 10px;

      &--adress {
        & p {
          margin-bottom: 2px;
          font-size: 12px;
        }
        & p:nth-child(1) {
          display: flex;
          justify-content: space-between;
        }
        margin-bottom: 10px;
      }
    }
    &__mail {
      & p {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: 12px;
      }
    }
  }
}

